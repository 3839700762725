





































































import BaseLink from "@/components/Base/BaseLink.vue";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import OthersSimpleLine from "@/components/Others/SimpleLine.vue";
import { loadFile } from "@/helpers/useLoader";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseWrapper,
        OthersSimpleLine,
        BaseLink,
    },
})
export default class WebMainCompanies extends Vue {
    @Prop() sponsors!: any[];

    loadFile(path: string) {
        return loadFile(path);
    }
}
