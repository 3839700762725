






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OthersSimpleLine extends Vue {
    @Prop({ default: "primary" }) color!: string;
    @Prop({ default: 28 }) width!: number | string;
    @Prop({ default: 3 }) height!: number | string;
}
